import usePageHeight from 'lib/hooks/usePageHeight';
import Image from 'next/image';
import AppStore from 'public/images/appstore.png';
import GooglePlay from 'public/images/googleplay.png';
import React from 'react';

export default function AppPlaceholder({
  title = 'Oops looks like you opened this link on desktop.',
  message = 'Try downloading the Greener app and opening again on your mobile.',
  right,
  showDownload = true,
}: {
  title?: string;
  message?: string;
  right?: React.ReactNode;
  showDownload?: boolean;
}) {
  const pageHeight = usePageHeight();

  return (
    <>
      <div
        className="grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-28"
        style={{ minHeight: pageHeight }}
      >
        <div className="py-4 md:py-24">
          <h2 className="mb-5">{title}</h2>
          <p className="p3 mb-10 text-gray-700">{message}</p>
          {showDownload ? (
            <div className="flex flex-col">
              <div className="flex flex-wrap gap-2 md:gap-5">
                <a
                  href="https://apps.apple.com/au/app/greener/id1535120978"
                  target="_blank"
                  rel="noreferrer"
                  aria-label="App Store"
                  className="relative"
                >
                  <Image
                    src={AppStore}
                    alt="App Store"
                    width={167}
                    height={48}
                    layout="fixed"
                  />
                </a>
                <a
                  href="https://play.google.com/store/apps/details?id=com.greenr.app"
                  target="_blank"
                  rel="noreferrer"
                  aria-label="Google Play"
                  className="relative"
                >
                  <Image
                    src={GooglePlay}
                    alt="Google Play"
                    width={167}
                    height={48}
                    layout="fixed"
                  />
                </a>
              </div>
              {/* <PrimaryButton className="flex-shrink">
                Open in the Greener App
              </PrimaryButton> */}
            </div>
          ) : null}
        </div>
        <div className="py-4 md:py-24">
          <div className="relative w-full md:w-[500px]">{right}</div>
        </div>
      </div>
    </>
  );
}
