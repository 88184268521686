import AppPlaceholder from 'components/AppPlaceholder/AppPlaceholder';
import { Footer } from 'components/Footer';
import { Header } from 'components/Header';
import Wrapper from 'containers/Wrapper';
import React from 'react';

export default function Custom404() {
  return (
    <>
      <Header />
      <Wrapper>
        <AppPlaceholder
          title="Oh no, this page could not be found!"
          message=""
          showDownload={false}
        />
      </Wrapper>
      <Footer />
    </>
  );
}
